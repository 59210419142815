import React, { createContext, ReactNode, useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_CONFIG, AMPLITUDE_EVENTS } from 'lib/constants/amplitudeConfig';

type AmplitudeContextType = {
  trackEvent: (eventName: AMPLITUDE_EVENTS, eventProperties: Record<string, any>) => void;
};

export const AmplitudeContext = createContext<AmplitudeContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trackEvent: () => {},
});

const AmplitudeProvider = ({ children }: { children: ReactNode }) => {
  const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!amplitudeApiKey) {
      console.warn('Amplitude API Key not provided');
      return;
    }
    amplitude.init(amplitudeApiKey, AMPLITUDE_CONFIG);
    setInitialized(true);
  }, []);

  const trackEvent = (eventName: AMPLITUDE_EVENTS, eventProperties: Record<string, any>) => {
    if (!initialized) {
      console.warn('event not tracked, amplitude not initialized');
      return;
    }
    amplitude.track(eventName, eventProperties);
  };
  return <AmplitudeContext.Provider value={{ trackEvent }}>{children}</AmplitudeContext.Provider>;
};

export default AmplitudeProvider;
