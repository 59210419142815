import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'pages/App';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from 'styled-components';
import theme from 'lib/theme';
import 'antd/dist/antd.min.css';
import './auth0.css';
import './textEditorOverrides.css';
import AmplitudeProvider from 'lib/contexts/amplitudeContext';

const root = document.getElementById('root');
const queryParams = new URLSearchParams(window.location.search);
const isOnboarding =
  window.location.pathname.includes('onboarding') || queryParams.get('onboarding');

ReactDOM.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    authorizationParams={{
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      redirect_uri: `${window.location.origin}${isOnboarding ? '/onboarding' : '/'}`,
    }}
  >
    <ThemeProvider theme={theme}>
      <AmplitudeProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </AmplitudeProvider>
    </ThemeProvider>
  </Auth0Provider>,
  root,
);
