import React from 'react';
import { Form, message } from 'antd';

import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';

import { Description, Title } from 'shared-components/ModalsContent';

import Modal from 'components/Modal/Modal';
import { Input, Item } from 'components/Form';

interface IProps {
  pacUuid: string;
  optionUuid: string;
  onClose: () => void;
  formUserId: string;
  setFormUserId: (value: string) => void;
  formId: string;
  setFormId: (value: string) => void;
  formConsentId: string;
  setFormConsentId: (value: string) => void;
}

const EditFormFieldCollectors = ({
  pacUuid,
  optionUuid,
  onClose,
  formUserId,
  setFormUserId,
  formId,
  setFormId,
  formConsentId,
  setFormConsentId,
}: IProps) => {
  const [form] = Form.useForm();

  const onPrimaryClick = async () => {
    try {
      await UsercentricsCmpIntegrationApiService.updateDataCollectors({
        pacUuid,
        formSelector: form.getFieldValue('formId'),
        userSelector: form.getFieldValue('formUserId'),
        consentsSelector: [
          {
            optionUuid,
            inputSelector: form.getFieldValue('formConsentId'),
          },
        ],
      });
    } catch (error) {
      message.error('Something wrong happened while editing');
    }

    setFormUserId(form.getFieldValue('formUserId'));
    setFormId(form.getFieldValue('formId'));
    setFormConsentId(form.getFieldValue('formConsentId'));
    onClose();
  };

  return (
    <Modal
      visible
      title="Edit JS Code Snippet"
      secondaryButton={{
        label: 'Cancel',
        onClick: () => onClose(),
      }}
      primaryButton={{
        label: 'Save Changes',
        onClick: onPrimaryClick,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      <Title data-cy="js-code-snippet-modal-title">Edit code to capture form submissions</Title>
      <Description>
        You can update the data capture script, by adjusting IDs of the form elements that are used
        to capture user email and consent decision. To get this data you will need to check the HTML
        code of your page and look for values in &quot;id&quot; parameters of the fields that
        correspond with the data needed.
      </Description>
      <Form
        name="js-code-snippet-form"
        form={form}
        initialValues={{
          formUserId,
          formId,
          formConsentId,
        }}
        style={{ padding: '16px' }}
      >
        <Item
          label="ID of the form script to be integrated into"
          name="formId"
          data-cy="input-formScript"
        >
          <Input placeholder="Field ID" />
        </Item>

        <Item
          label="ID of the field that contains Email"
          name="formUserId"
          data-cy="input-emailField"
        >
          <Input placeholder="Field ID" />
        </Item>

        <Item
          label="ID of the field that contains consent decision"
          name="formConsentId"
          data-cy="input-consentField"
        >
          <Input placeholder="Field ID" />
        </Item>
      </Form>
    </Modal>
  );
};

export default EditFormFieldCollectors;
