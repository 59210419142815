import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_410_15163)">
      <rect
        x="4"
        y="3.5"
        width="32"
        height="32"
        rx="3.2"
        fill="white"
        shapeRendering="crispEdges"
      />
      <rect
        x="4.4"
        y="3.9"
        width="31.2"
        height="31.2"
        rx="2.8"
        stroke="#0045A5"
        strokeOpacity="0.1"
        strokeWidth="0.8"
        shapeRendering="crispEdges"
      />
      <g clipPath="url(#clip0_410_15163)">
        <path
          d="M22.4008 25.9H14.4008V15.5C14.4008 15.06 14.0408 14.7 13.6008 14.7C13.1608 14.7 12.8008 15.06 12.8008 15.5V25.9C12.8008 26.78 13.5208 27.5 14.4008 27.5H22.4008C22.8408 27.5 23.2008 27.14 23.2008 26.7C23.2008 26.26 22.8408 25.9 22.4008 25.9ZM26.4008 22.7V13.1C26.4008 12.22 25.6808 11.5 24.8008 11.5H17.6008C16.7208 11.5 16.0008 12.22 16.0008 13.1V22.7C16.0008 23.58 16.7208 24.3 17.6008 24.3H24.8008C25.6808 24.3 26.4008 23.58 26.4008 22.7ZM24.8008 22.7H17.6008V13.1H24.8008V22.7Z"
          fill="url(#paint0_linear_410_15163)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_410_15163"
        x="0.8"
        y="0.3"
        width="40.528"
        height="40.528"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.064" dy="1.064" />
        <feGaussianBlur stdDeviation="2.132" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_410_15163" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_410_15163"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_410_15163"
        x1="12.8008"
        y1="11.5"
        x2="26.4159"
        y2="11.5129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <clipPath id="clip0_410_15163">
        <rect width="19.2" height="19.2" fill="white" transform="translate(10.4004 9.8999)" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
