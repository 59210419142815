import React, { useState } from 'react';
import { Typography, Tooltip } from 'antd';
import Logo from 'components/Logo';

import { OnboardingStorageService } from 'lib/services/onboardingStorageService';

import AllSet from 'assets/AllSet.svg';
import GoogleTagManager from 'assets/GoogleTagManager.svg';
import ExternalLink from 'assets/ExternalLink.svg';
import Shield from 'assets/Shield.svg';
import OtherImplementations from 'assets/OtherImplementations.svg';
import { NewCopy } from 'components/Icons';
import { clickToCopy } from 'lib/helpers';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  OnboardingContent,
  SubTitle,
  SnippetContainer,
  CodePlacement,
  CodeToCopy,
  LineDivider,
  ExternalLinks,
  TopButtons,
  ScrollableContainer,
  OnboardingSidebarTitle,
} from './styled';
import { BuildHtmlToCopy } from './OnboardingHelper';

const { Title } = Typography;

const Step3 = () => {
  const navigate = useNavigate();

  const [copiedClicked, setCopiedClicked] = useState<boolean>(false);
  const result = OnboardingStorageService.getOnboardingResults();
  const selectors = OnboardingStorageService.getStep2Data();

  const handleAdminRedirect = () => {
    navigate('/configurations');
  };

  return (
    <Wrapper>
      <Sidebar
        style={{
          backgroundImage: `url(${Shield})`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <img src={AllSet} alt="All set" />
          <OnboardingSidebarTitle level={1}>You are all set!</OnboardingSidebarTitle>
          <span>
            Well done! You have successfully configured your Preference Manager Audience Unlocker.
            Follow the steps to implement it to your website.
          </span>
        </div>
      </Sidebar>

      <OnboardingContent>
        <ScrollableContainer>
          <div style={{ margin: 'auto' }}>
            <Title level={3}>Add to website via HTML</Title>
            <SubTitle>
              Step 1: Paste the script tag below into the &lt;head&gt; section of your website
            </SubTitle>

            <SnippetContainer>
              <Tooltip title={copiedClicked ? 'Copied' : 'Copy to clipboard'} placement="right">
                <NewCopy
                  onClick={() => {
                    clickToCopy(
                      '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>',
                      setCopiedClicked,
                    );
                  }}
                />
              </Tooltip>
              <CodePlacement>
                <CodeToCopy>
                  <code
                    style={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '550px',
                      color: '#303030',
                    }}
                  >
                    {
                      '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>'
                    }
                  </code>
                </CodeToCopy>
              </CodePlacement>
            </SnippetContainer>
            <SubTitle style={{ marginTop: '16px' }}>
              Step 2: Add the code below right after the data capture form
            </SubTitle>

            <SnippetContainer>
              <Tooltip title={copiedClicked ? 'Copied' : 'Copy to clipboard'} placement="right">
                <NewCopy
                  onClick={() => {
                    clickToCopy(
                      BuildHtmlToCopy(
                        selectors?.formSelector,
                        result?.widgetUuid,
                        result?.pacUuid,
                        result?.publicApiKey,
                        selectors?.userIdSelector,
                        selectors?.consentSelector,
                        result?.optionUuid,
                      ),
                      setCopiedClicked,
                    );
                  }}
                />
              </Tooltip>
              <CodePlacement>
                <CodeToCopy>
                  <code
                    style={{
                      overflow: 'scroll',
                      height: '134px',
                      display: 'block',
                      width: '550px',
                      color: '#303030',
                    }}
                  >
                    {'<script>'}
                    <br />
                    &nbsp;&nbsp;{'window.setupForm({'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{'event: {'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elementSelector: &quot;
                    <strong>#{selectors?.formSelector || '[INSERT_FORM-ID_HERE]'}</strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;eventType: &quot;submit&quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;observeDomChanges: false,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{'},'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;widgetId: &quot;
                    <strong>{result?.widgetUuid}</strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;pacId: &quot;
                    <strong>{result?.pacUuid}</strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;publicApiKey: &quot;
                    <strong>{result?.publicApiKey || '[INSERT_PUBLIC-API-KEY_HERE]'}</strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{'userId: {'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;
                    <strong>
                      #{selectors?.userIdSelector || '[INSERT_USEREMAIL-FIELD-ID_HERE]'}
                    </strong>
                    &quot;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;inputs: [
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;
                    <strong>
                      #{selectors?.consentSelector || '[INSERT_CONSENT-FIELD-ID_HERE]'}
                    </strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'validate: function () {'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return true;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;optionUuid: &quot;
                    <strong>{result?.optionUuid}</strong>
                    &quot;,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;]
                    <br />
                    {'});'}
                    <br />
                    {'</script>'}
                  </code>
                </CodeToCopy>
              </CodePlacement>
            </SnippetContainer>
            <SubTitle style={{ marginTop: '16px' }}>
              Step 3: Add the Preference Center hyperlink to your website
            </SubTitle>
            <SnippetContainer>
              <Tooltip title={copiedClicked ? 'Copied' : 'Copy to clipboard'} placement="right">
                <NewCopy
                  onClick={() => {
                    clickToCopy(
                      `https://app-preference.preference-management.usercentrics.eu/login/${result.pacUuid}?lng=[LANG_CODE]`,
                      setCopiedClicked,
                    );
                  }}
                />
              </Tooltip>
              <CodePlacement>
                <CodeToCopy>
                  <code
                    style={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '550px',
                      color: '#303030',
                    }}
                  >
                    https://app-preference.preference-management.usercentrics.eu/login/
                    {result.pacUuid}?lng=[LANG_CODE]
                  </code>
                </CodeToCopy>
              </CodePlacement>
            </SnippetContainer>
            <LineDivider />
            <Title level={3}>Add to website via other methods</Title>
            <ExternalLinks>
              <img src={GoogleTagManager} alt="Google Tag Manager" />
              <a
                href="https://support.usercentrics.com/hc/en-us/articles/16744912112540-Integrate-Audience-Unlocker-with-Google-Tag-Manager"
                target="_blank"
                rel="noreferrer"
                className="full-with"
              >
                Google Tag Manager (GTM)
              </a>
              <a
                href="https://support.usercentrics.com/hc/en-us/articles/16744912112540-Integrate-Audience-Unlocker-with-Google-Tag-Manager"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ExternalLink} alt="External Link" />
              </a>
            </ExternalLinks>

            <ExternalLinks style={{ marginTop: '16px' }}>
              <img src={OtherImplementations} alt="Other Implementations" />
              <a
                href="https://support.usercentrics.com/hc/en-us/articles/16742483326620-Setting-up-your-Audience-Unlocker"
                target="_blank"
                rel="noreferrer"
                className="full-with"
              >
                Other implementation methods
              </a>
              <a
                href="https://support.usercentrics.com/hc/en-us/articles/16742483326620-Setting-up-your-Audience-Unlocker"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ExternalLink} alt="External Link" />
              </a>
            </ExternalLinks>
          </div>
        </ScrollableContainer>
        <TopButtons style={{ justifyContent: 'right' }}>
          <Button
            type="black"
            size="large"
            onClick={handleAdminRedirect}
            htmlType="submit"
            data-cy="button-finishSetup"
          >
            Done
          </Button>
        </TopButtons>
      </OnboardingContent>
    </Wrapper>
  );
};

export default Step3;
