import type { Types } from '@amplitude/analytics-browser';

export const AMPLITUDE_CONFIG: Types.BrowserOptions = {
  autocapture: true,
  serverZone: 'EU',
  trackingOptions: {
    ipAddress: false,
  },
} as const;

export enum AMPLITUDE_EVENTS {
  SUCCESSFUL_ONBOARD_LOGIN = 'Successful Login',
}
